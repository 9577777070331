import { template as template_0f4646c370c24fd9a8cdc875bfbeb2f4 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const OpLikesCell = template_0f4646c370c24fd9a8cdc875bfbeb2f4(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="op_likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default OpLikesCell;
