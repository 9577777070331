import { template as template_cb0d020d72d84c46bb284f618239e508 } from "@ember/template-compiler";
const FKControlMenuContainer = template_cb0d020d72d84c46bb284f618239e508(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
