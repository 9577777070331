import { template as template_fc61681adf384bd1b5394242eb5e9a2d } from "@ember/template-compiler";
const FKLabel = template_fc61681adf384bd1b5394242eb5e9a2d(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
